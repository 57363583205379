import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import "katex/dist/katex.min.css";
import Breadcrumb from "../components/Breadcrumb";

const post = ({ data }) => {
  const {
    html,
    frontmatter: { title, type, slug },
  } = data.markdownRemark;

  return (
    <Layout active={`${type}s`}>
      <title>{title} | DoingCloudStuff</title>
      <Breadcrumb type={type} slug={slug} title={title} />
      <div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        type
        slug
      }
      html
    }
  }
`;
export default post;

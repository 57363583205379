import { Link } from "gatsby";
import React from "react";

const divider = {
  "--bs-breadcrumb-divider": "'>'",
  fontSize: "1.2em",
};

const Breadcrumb = ({ type, slug = null, title = "" }) => {
  const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);

  return (
    <>
      <nav className="p-0 mt-0 mb-5" style={divider} aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item" key="home">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item" key={type}>
            <Link to="/posts">Posts</Link>
          </li>
          <li key={slug} className="breadcrumb-item active" aria-current="page">
            {capitalize(title)}
          </li>
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumb;
